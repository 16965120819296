@import "src/scss/base/mixins.scss";
@import "src/scss/base/variable.scss";
@import "src/scss/base/breakpoints.scss";

.image {
  display: block;
  flex-basis: 100%;
  padding: 10px;
  box-sizing: border-box;
  opacity: 1;
  transition: 0.5s;

  & img {
    object-fit: cover;
  }

  @include xl {
    &:hover {
      opacity: 0.6;
    }
  }
}

/* increase images per line as browser gets larger using media queries
   this first query makes two images per line at 640px width */
@media only screen and (min-width: 640px) {
  .image {
    flex-basis: 50%;


    & img {
      aspect-ratio: 12/8;
    }
  }
}

@media only screen and (min-width: 1024px) {
  .image {
    flex-basis: 25%;
    & img {
      aspect-ratio: 12/8;
    }
  }
}

.videoButton {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.title {
  font-weight: 500;
  text-transform: uppercase;
}
