@import "src/scss/base/mixins.scss";
@import "src/scss/base/variable.scss";
@import "src/scss/base/breakpoints.scss";

.root {
  margin-top: auto;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;


  @include md {
    flex-direction: row;
  }
}

.copyRight {
  @include md {
    margin-right: 5px;
  }
}
