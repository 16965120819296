@import "src/scss/base/mixins.scss";
@import "src/scss/base/variable.scss";
@import "src/scss/base/breakpoints.scss";

.root {
  flex: 1;
}

.overlay {
  background-color: grey;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  /* Center the contents horizontally and vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.video {
  font-family: sans-serif;
  text-align: center;
}

.wrapper {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 auto;
}

.small {
  cursor: none;
}

.galleryRoot {
  flex: 1;
}

.galleryWrap {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
  justify-content: center;
  max-width: 620px;
  margin: 0 auto;
}

.galleryWrap .single {
  width: 200px;
  cursor: pointer;
}

.galleryWrap .single img {
  max-width: 100%;
}

.galleryWrap .single img:hover {
  transform: scale(1.02);
}

.sliderWrap {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.sliderWrap .btnClose,
.sliderWrap .btnPrev,
.sliderWrap .btnNext {
  position: fixed;
  cursor: pointer;
  opacity: 0.6;
  color: #fff;
  z-index: 2;
}

.sliderWrap .btnClose,
.sliderWrap .btnPrev,
.sliderWrap .btnNext {
  & svg {
    width: 30px;
    height: 30px;
  }
}

.btnNext:hover,
.btnPrev:hover,
.btnClose:hover,
.galleryTitle:hover {
  opacity: 1;
}

.sliderWrap .btnClose {
  top: 40px;
  right: 40px;
}

.sliderWrap .btnPrev {
  top: 50%;
  transform: translateY(-50%);
  left: 40px;
}

.sliderWrap .btnNext {
  top: 50%;
  transform: translateY(-50%);
  right: 40px;
}

.galleryTitle {
  bottom: 0;
  position: fixed;
  cursor: pointer;
  opacity: 0.6;
  color: #fff;
  z-index: 2;
}
.fullScreenImage {
  width: calc(100% - 40px);
  height: calc(100% - 70px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.fullScreenImage img {
  max-width: 100%;
  max-height: 100%;
  pointer-events: none;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  object-fit: cover;
}

.image {
  aspect-ratio: 5/4;
  object-fit: cover;
}

.title {
  margin-top: 15px;
  margin-bottom: 30px;
}

.root {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 auto;
}

.wrapper {
  display: flex;
  flex-direction: column;
  flex: 1 1;
}

.image {
  aspect-ratio: 12/8;
  object-fit: cover;
}

/* increase images per line as browser gets larger using media queries
   this first query makes two images per line at 640px width */
@media only screen and (min-width: 640px) {
  .wrapper {
    flex-basis: 50%;
  }
}

/* three images per line when browser width exceeds 960px */
@media only screen and (min-width: 960px) {
  .wrapper {
    flex-basis: 25%;
  }
}

.space {
  margin-left: 10px;
  margin-bottom: 15px;

  &:nth-child(2) {
    margin-bottom: 0;
  }

  @include xl {
    max-width: 50%;
  }

  &:nth-child(1) {
    margin-top: 50px;
  }
}

.modalOpen {
  z-index: 1;
}

.videoWrapper {
  position: relative;
}
