@import "src/scss/base/mixins.scss";
@import "src/scss/base/variable.scss";
@import "src/scss/base/breakpoints.scss";

.container {
  @include container-gutter;
  position: relative;
  padding-bottom: 80px;
  margin-top: 120px;
  height: calc(100% - 200px);
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - (120px + 80px));
}
