@import './breakpoints.scss';
@import './variable.scss';

@mixin generate-columns($media, $gutter, $count: 12) {
  $width: columnWidth(1, $count, $gutter);

  @include flex-basis(#{$width});

  @for $size from 1 through $count {
    $width: columnWidth($size, $count, $gutter);

    &--#{ $media }-#{ $size } {
      @include flex-basis(#{$width});
    }

    &-offset--#{ $media }-#{ $size } {
      margin-left: calc(#{$width} + #{$gutter});
    }

    &-offset-sec--#{ $media }-#{ $size } {
      margin-left: calc(#{$width} + 2 * #{$gutter});
    }
  }

  &-offset--#{ $media }-0 {
    margin-left: 0;
    padding-left: 0;
  }
}

@function columnWidth($size, $count, $gutter) {
  $multiplier: $size / $count;
  $result: calc(#{100 * $multiplier}% - #{$gutter * (1 - $multiplier)});

  @return $result;
}

@mixin flex-basis($value: auto) {
  flex-basis: $value;
  max-width: $value;
  width: $value;
  flex-shrink: 0;
}

@mixin container-gutter {
  margin: 0 auto;
  padding: 0 $container-padding-sm;
  width: 100%;

  .grid {
    padding: 0;
  }

  @include md {
    padding: 0 $container-padding-sm;
  }

  @include lg {
    padding: 0 $container-padding-lg;
  }

  @include xl {
    padding: 0 $container-padding-xl;
  }
   @include xxl {
      padding: 0 $header-padding-xxl;
    }
}

@mixin  visibleOnlyOnLargeBp {
  display: none;

  @include xl {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

@mixin  visibleOnlyOnSmallBp {
  @include xl {
    display: none;
  }
}