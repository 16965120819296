.modal {
  position: fixed;
  height: 100%;
  right: 0%;
  width: 100%;
  background: white;
  padding: 30px;
  z-index: 1;
}

.menuItemsWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.social {
  display: flex;
  position: absolute;
  bottom: 27px;
}

.mediaLink {
  margin-bottom: 10px;
  margin-right: 10px;

  & svg {
    width: 30px;
    height: 30px;
  }
}

.menuItems {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-top: 120px;
}

.menuItem {
  text-decoration: none;
  padding-bottom: 30px;
}

.menuBtn {
  position: absolute;
  top: 40px;
  left: 60%;
  left: 45%;
  width: 30%;
  & svg {
    width: 30px;
    height: 30px;
  }
}

.aside {
  height: 100vh;
}

.isLandscape {
  margin-top: 0;
}
