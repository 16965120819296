html {
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0;
  font-family: "Roboto";
}

button {
  outline: none;
}

:root {
  --vh: 1vh;
  height: 100vh;
}

.wrapper {
  height: 100%;
}

@keyframes slide-in-top {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}

.sticky {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 0 20px;
  z-index: 1;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  animation: slide-in-top 0.5s ease-in-out;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  overscroll-behavior-x: none;
  min-width: 320px;
  line-height: 1.5;
  text-rendering: optimizeSpeed;
}

.body__locked {
  overflow: hidden;
  width: 100%;
  touch-action: none;
}
