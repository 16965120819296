@import 'src/scss/base/mixins.scss';
@import 'src/scss/base/variable.scss';
@import 'src/scss/base/breakpoints.scss';

.grid {
  justify-content: space-between;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.grid__cell {
  flex-shrink: 0;

  &:first-child {
    padding-left: 0;
  }

  @include generate-columns(sm, $gutter-sm, 4);

  @include md {
    @include generate-columns(md, $gutter-md, 6);
  }

  @include lg {
    @include generate-columns(lg, $gutter-lg);
  }

  @include xl {
    @include generate-columns(xl, $gutter-lg, 13);
  }
}

