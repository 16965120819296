@import "src/scss/base/mixins.scss";
@import "src/scss/base/variable.scss";
@import "src/scss/base/breakpoints.scss";

.root {
  margin: auto;
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  padding: 0 10px;
  margin: auto;

  @include lg {
    flex-direction: column-reverse;
  }
}

.textRoot {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
}

@media only screen and (min-width: 1204px) {
  .imageWrapper {
    max-width: 640px;
    max-height: 640px;
  }

  .textWrapper {
    margin-top: 15%;
    max-width: 70%;
  }

  .root {
    flex-direction: row;
  }
}

.text {
  padding-bottom: 20px;
}

.title {
  margin: 15px 0 25px 0;
}

.headline {
  max-width: 40ch;
  margin-bottom: 30px;
  margin-top: 20px;

  @include lg {
    margin-top: 0;
    margin-bottom: 50px;
  }
}

.spanOne,
.spanTwo {
  display: inline-block;
  opacity: 0;
  filter: blur(4px);
}

.spanOne {
  padding-right: 10px;
}

.spanOne {
  animation: fade-in 0.8s 0.4s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

.spanTwo {
  animation: fade-in 0.8s 0.5s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

@keyframes fade-in {
  100% {
    opacity: 1;
    filter: blur(0);
  }
}

.link {
  background-color: black;
  padding: 0 0.2rem;
  color: #fff;
  -webkit-transform: rotate(-1deg) scale(1.03);
  transform: rotate(-1deg) scale(1.03);
  display: inline-block;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  margin: 0 5px;

  &:hover {
    background-color: transparent;
    color: black;
    -webkit-transform: rotate(-2deg) scale(1);
    transform: rotate(-2deg) scale(1);
  }
}
