@import "src/scss/base/mixins.scss";
@import "src/scss/base/variable.scss";
@import "src/scss/base/breakpoints.scss";

.menu {
  display: flex;
  align-items: center;
  gap: 24px;
}

.side {
  align-items: flex-start;
  flex-direction: column;
  padding: 96px 24px 24px 32px;
  background-color: grey;
  width: 280px;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
}

.menuItem {
  text-decoration: none;
  padding: 15px 10px;
}

.menuBtn {
  display: flex;
  place-items: center;

  border: none;
  padding: 10px;
  cursor: pointer;
  z-index: 3;

  & svg {
    width: 40px;
    height: 40px;
  }
}

@media screen and (width: 1024px) {
  .menuBtn {
    display: none;
  }
}

@media screen and (min-width: 1024px) {
  .menuBtn {
    display: none;
  }
}

@media screen and (max-width: 640px) {
  .menuBtn {
    & svg {
      width: 30px;
      height: 30px;
    }
  }
}

.menuSmall {
  background-color: white;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 270px;
  z-index: 1000;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.menuOverlay {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.menuItems {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-top: 120px;
}

.imageWrapper {
  height: 100%;
  margin: 0 auto;
}

@media only screen and (min-width: 1024px) {
  .imageWrapper {
    height: 80px;
    width: 80px;
  }
}

.image {
  width: 100%;
  height: 100%;
  //   margin-left: -14px;
}

.logo {
  width: fit-content;
  text-align: center;
  cursor: pointer;
}

.menuItemsWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.social {
  display: flex;
  margin-left: 10px;
  position: absolute;
  bottom: 10px;
}

.mediaLink {
  margin-bottom: 10px;
  margin-right: 10px;

  & svg {
    width: 30px;
    height: 30px;
  }
}

.headerSticky {
  top: -120px;
}

.root {
  display: block;
  position: fixed;
  height: 120px;

  margin: 0 auto;
  padding: 0 $container-padding-sm;
  width: 100%;

  .grid {
    padding: 0;
  }

  @include md {
    padding: 0 $container-padding-sm;
  }

  @include lg {
    padding: 0 $container-padding-lg;
  }

  @include xl {
    padding: 0 $container-padding-xl;
  }

  @include xxl {
    padding: 0 $header-padding-xxl;
  }

  max-width: inherit;
  z-index: 1;
  display: flex;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  transition: transform 0.2s ease-in-out 0.2s;
  justify-content: space-between;

  &.isHidden{
  display: none;
  }
}

.modalOpen {
  z-index: 0;
}

.up {
  top: 0;
  transition: top 0.4s ease-out;
}

.down {
  top: -120px;
  transition: top 0.4s ease-out;
}

.isOpenHeader {
  position: fixed;
}
