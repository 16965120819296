@import 'src/scss/base/mixins.scss';
@import 'src/scss/base/variable.scss';
@import 'src/scss/base/breakpoints.scss';

$grid-overlay-column-pink: #fc03be;

.grid-overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: $layer-1;
  display: none;
  @include container-gutter;
  margin: auto;

  .grid {
    height: 100%;
  }
}

.grid-overlay--visible {
  display: block;
  pointer-events: none;
}

.grid-overlay__column {
  height: 100%;
  background: $grid-overlay-column-pink;
  opacity: 0.3;
}
