.root {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.wrapper {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 auto;
}

.image {
  display: block;
  flex-basis: 100%;
  padding: 10px;
  box-sizing: border-box;

  & img {
    object-fit: cover;
  }
}

@media only screen and (min-width: 640px) {
  .image {
    flex-basis: 50%;
    & img {
      aspect-ratio: 12/8;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .card {
    margin-bottom: 50px;
  }
}

@media only screen and (min-width: 1024px) {
  .wrapper {
    min-height: calc(100% - 120px);
    margin: auto;
    display: block;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
    scroll-snap-type: x mandatory;
    &::-webkit-scrollbar {
      display: none;
    }
  }

    .root {
      padding: 0 10px;
    }

  .card {
    display: inline-block;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }

    & img {
      height: 600px;
      width: 100%;
    }
  }
}
