@import "src/scss/base/mixins.scss";
@import "src/scss/base/variable.scss";
@import "src/scss/base/breakpoints.scss";

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: auto;
}

.title {
  margin-bottom: 15px;
}

.media {
  margin-top: 50px;
  display: flex;
  justify-content: space-around;
  flex-direction: column;

  @include md {
    flex-direction: row;
  }
}

.mediaLink {
  margin-bottom: 15px;

  & svg {
    width: 30px;
    height: 30px;
  }
}

.logo-overlay {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999; /* Make sure the logo is on top of other content */
}
