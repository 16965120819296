// Medium breakpoint
@mixin md() {
  @media (min-width: $breakpoint-md) {
    @content;
  }
}

// Large breakpoint
@mixin lg() {
  @media (min-width: $breakpoint-lg) {
    @content;
  }
}

// XLarge breakpoint
@mixin xl() {
  @media (min-width: $breakpoint-xl) {
    @content;
  }
}

// XXLarge breakpoint
@mixin xxl() {
  @media (min-width: $breakpoint-xxl) {
    @content;
  }
}